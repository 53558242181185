const sizes = [
  {
    name: "32-S",
  },
  {
    name: "34-M",
  },
  {
    name: "36-L",
  },
  {
    name: "38-XL",
  },
  {
    name: "40-Q",
  },
  {
    name: "42-EQ",
  },
  {
    name: "44-Free",
  },
];

export default sizes;
