export const UPDATE_SHIPPING_CART_CLIENT = "UPDATE_SHIPPING_CART_CLIENT";

export const ADD_SHIPPING_CART_ITEM = "ADD_SHIPPING_CART_ITEM";

export const UPDATE_SHIPPING_CART_ITEM_UNIT = "ADD_SHIPPING_CART_ITEM_UNIT";

export const REMOVE_SHIPPING_CART_ITEM = "REMOVE_SHIPPING_CART_ITEM";

export const CLEAR_SHIPPING_CART_ITEM = "CLEAR_SHIPPING_CART_ITEM";

export const FORMAT_SHIPPING_CART_SUCCESS = "FORMAT_SHIPPING_CART_SUCCESS";
