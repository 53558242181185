import {
    CBadge,
    CNavGroup,
    CNavItem,
    CNavTitle,
    CSidebarNav,
} from "@coreui/vue";
import { defineComponent, h, onMounted, ref, resolveComponent } from "vue";
import { RouterLink, useRoute } from "vue-router";
import store from "../store";

import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
import _adminNav from "./_adminNav";
import _employeeNav from "./_employeeNav";

const { isAdmin } = store.getters;

const normalizePath = (path) =>
    decodeURI(path)
        .replace(/#.*$/, "")
        .replace(/(index)?\.(html)$/, "");

const isActiveLink = (route, link) => {
    if (link === undefined) {
        return false;
    }

    if (route.hash === link) {
        return true;
    }

    const currentPath = normalizePath(route.path);
    const targetPath = normalizePath(link);

    return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
    if (isActiveLink(route, item.to)) {
        return true;
    }

    if (item.items) {
        return item.items.some((child) => isActiveItem(route, child));
    }

    return false;
};

const TheSidebarNav = defineComponent({
    name: "TheSidebarNav",
    data() {
        return {
            nav: [],
            buffor: [],
        };
    },
    components: {
        CNavItem,
        CNavGroup,
        CNavTitle,
    },
    setup(props, context) {
        const route = useRoute();
        const firstRender = ref(true);

        onMounted(() => {
            firstRender.value = false;
        });

        const renderItem = (item) => {
            if (item.items) {
                return h(
                    CNavGroup,
                    {
                        as: "div",
                        compact: true,
                        ...(firstRender.value && {
                            visible: item.items.some((child) =>
                                isActiveItem(route, child)
                            ),
                        }),
                    },
                    {
                        togglerContent: () => [
                            h(resolveComponent("CIcon"), {
                                customClassName: "nav-icon",
                                name: item.icon,
                            }),
                            item.name,
                        ],
                        default: () =>
                            item.items.map((child) => renderItem(child)),
                    }
                );
            }
            return item.to
                ? h(
                      RouterLink,
                      {
                          to: item.to,
                          custom: true,
                      },
                      {
                          default: (props) =>
                              h(
                                  resolveComponent(item.component),
                                  {
                                      active: props.isActive,
                                      as: "div",
                                      href: props.href,
                                      onClick: () => props.navigate(),
                                  },
                                  {
                                      default: () => [
                                          item.icon
                                              ? h(resolveComponent("CIcon"), {
                                                    customClassName: "nav-icon",
                                                    name: item.icon,
                                                })
                                              : h(
                                                    "span",
                                                    { class: "nav-icon" },
                                                    h("span", {
                                                        class: "nav-icon-bullet",
                                                    })
                                                ),
                                          item.name,
                                          item.badge &&
                                              h(
                                                  CBadge,
                                                  {
                                                      class: "ms-auto",
                                                      color: item.badge.color,
                                                  },
                                                  {
                                                      default: () =>
                                                          item.badge.text,
                                                  }
                                              ),
                                      ],
                                  }
                              ),
                      }
                  )
                : h(
                      resolveComponent(item.component),
                      {
                          as: "div",
                      },
                      {
                          default: () => item.name,
                      }
                  );
        };

        return () =>
            h(
                CSidebarNav,
                {
                    as: simplebar,
                },
                {
                    default: () =>
                        isAdmin
                            ? _adminNav.map((item) => renderItem(item))
                            : _employeeNav.map((item) => renderItem(item)),
                }
            );
    },
});

export { TheSidebarNav };
