<template>
    <CFooter :fixed="false">
        <div>
            <span class="ml-1"
                >&copy; {{ new Date().getFullYear() }} 永行貿易有限公司.</span
            >
        </div>
        <div class="ml-auto">
            <span class="mr-1">Powered by</span>
            <a href="／" target="_blank">永行貿易有限公司</a>
        </div>
    </CFooter>
</template>
